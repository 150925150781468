@import "../../../../../variables.css";

.content {
    padding-left: 2.5rem;
}

.message {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.2;
    color: #0147be;
    padding-bottom: 2.5rem;
}

@media (min-width: var(--ark-new-med-small-mobile-above-361)) {
    .content {
        padding-left: 0;
        margin: 0 auto;
        max-width: 20rem;
    }
}

@media (max-width: var(--ark-new-med-small-mobile-360)) {
    .content {
        padding-left: 1.25rem;
    }
}