@import "../../../../../variables.css";

.content {
    padding-left: 2.5rem;
}

.content ul {
    box-shadow: 0 2px 4px 0 var(--ark-black-15-color);
    border-radius: var(--ark-border-radius);
    margin-top: var(--ark-spacing-xxs);
}

.countryListItem {
    height: 3rem;
    display: grid;
    grid-template-columns: 1.75rem minmax(0, 1fr);
    grid-gap: 1rem;
    align-items: center;
    padding-left: 1.5rem;
    text-align: left;
}

.countryListItem:hover {
    background-color: var(--ark-highlights-green-20);
}

.content ul li:first-child .countryListItem {
    border-radius: var(--ark-border-radius) var(--ark-border-radius) 0 0;
}

.content ul li:last-child .countryListItem {
    border-radius: 0 0 var(--ark-border-radius) var(--ark-border-radius);
}

.countryFlag {
    width: 1.75rem;
    font-size: 1.125rem;
    border-radius: 2px;
}

.countryListItem:hover {
    cursor: pointer;
}

.countryName {

}

.input {
    position: relative;
}

.searchIcon {
    position: absolute;
    top: 0.75rem;
    right: 0.5625rem;
}

.warningMessage {
    color: #0147be;
    font-size: 1.25rem;
    font-weight: var(--ark-font-weight-regular);
    text-align: center;
    line-height: 1.5rem;
    width: 100%;
    margin-bottom: 2.5rem;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {

    .warningMessage {
        max-width: 20rem;
        margin: 0 auto 1.5rem;
        font-weight: var(--ark-font-weight-bold);
        text-align: left;
    }
}

@media (min-width: var(--ark-new-med-small-mobile-above-361)) {
    .content {
        padding-left: 0;
        margin: 0 auto;
        max-width: 20rem;
    }
}

@media (min-width: var(--ark-small-desktop-figma-1024)) {
    .content ul {
        margin-left: -1.875rem;
    }
}

@media (max-width: var(--ark-new-med-small-mobile-360)) {
    .content {
        padding-left: var(--ark-spacing-m);
        padding-right: var(--ark-spacing-m);
    }
    .warningMessage {
        padding-left: var(--ark-spacing-m);
        padding-right: var(--ark-spacing-m);
        margin: 0 0 1.5rem;
    }
}

@media (max-width: var(--ark-small-mobile-576)) {
    .countryListItem {
        padding-left: 0;
    }
    .content ul {
        box-shadow: none;
    }
}