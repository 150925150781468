@import '../../../variables.css';

.panel {
    max-height: 100vh;
    width: 47rem;
    position: fixed;
    z-index: 100000;
    top: 0;
    right: 0;
    bottom: 0;
    background: white;
    box-shadow: -2px 2px 4px 0 var(--ark-black-15-color);
    transition: 0.6s;
}

.myNodeEnter {
    transform: translate(100%);
}

.myNodeExit {
    transform: translate(0);
}

.myNodeEnterActive {
    transition: transform 0.6s;
    transform: translate(0);
}

.myNodeExitActive {
    transition: transform 0.6s;
    transform: translate(100%);
}
.myNodeExitDone {
    transform: translate(100%);
}


.panelCaption.avatarsPanel{
    padding-left: 0;
    height: 100%;
}

.header {
    height: 6.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navButton::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--ark-primary-blue-figma);
    height: 6px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    transform-origin: center;
    transform: scale(0);
    transition: transform 0.332s var(--ark-ease-in-out-sine);
}

.navButton::after {
    content: ' ';
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 2rem;
    left: 50%;
    transform-origin: 0;
    transform: scale(1) translateX(-50%);
    opacity: 0;
    border: 6px solid var(--ark-primary-50-color);
    transition: opacity 0.231s linear, transform 0.231s var(--ark-ease-in-out-sine);
}

.navButton:active::after {
    transform: scale(0.6) translateX(-50%);
    opacity: 0.5;
    transition: 0s;
}

.navButtonIcon {
    color: #31323d;
    transition: color 0.531s var(--ark-ease-in-out-sine);
}

.navButton:hover .navButtonIcon {
    color: var(--ark-primary-blue-figma-font);
}

.iconCaption {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-22-line-height);
    color: var(--ark-black-color);
}

.navButton {
    position: relative;
    height: 5.75rem;
    width: 7.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    color: var(--ark-gray-medium-color);
    background: none;
    border: none;
    box-shadow: none;
}

.content {
    padding-top: 1.5rem;
    height: calc(100% - 13rem);
    overflow: auto;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    height: 4rem;
    width: 100%;
}

.footer::before {
    content: ' ';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 12.5rem;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 50%);
    pointer-events: none;
}

.footer__content {
    display: grid;
    grid-template-columns: 9.375rem 9.375rem;
    grid-gap: 3.75rem;
}

.closeBtn {
    width: 3rem; /* 48px for mobile*/
    height: 3rem; /* 48px for mobile*/
    padding: 0;
    background: none;
    box-shadow: none;
    border: 0;
}

@media (orientation: landscape) {
    .content {
        height: calc(100% - 10.25rem);
    }
}

/* 566px when keyboard appear on portrait mode android */
@media (min-width: 566px) and (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    .panel {
        padding-left: 5.78rem;
    }
    .panelCaption.avatarsPanel{
        height: 100%;
        width: 100%;
    }
 
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .panel {
        max-width: 100%;
        width: 100%;
    }

    .content {
        padding-top: 0;
    }

    .panelCaption {
        /* padding-left: 1.5rem; */
    }

    .panelCaption.avatarsPanel{
        margin: 0;
        width: 34rem;
    }


    .panelCaption.PhoneInputPanel {
        width: 22.8rem;
    }

    .panelCaption.FirstNamePanel,
    .panelCaption.LastNamePanel,
    .panelCaption.ScreenNamePanel,
    .panelCaption.EmailPanel {
        width: 23.75rem;
    }

    .panel.isOpened {
        transform: translate(0);
        display: initial;
    }

    .leveled {
        z-index: 1000;
    }

    .header {
        position: relative;
        /* box-shadow: none; */
        height: 4.875rem;
    }
}


@media (min-width: var(--ark-small-mobile-576)) and (max-width: var(--ark-small-desktop-figma-1024)) {
    .panelCaption.PhoneInputPanel {
        width: 24.8rem;
    }
    .panelCaption.CountryPickerPanel {
        width: 20rem;
    }
    .panelCaption.FirstNamePanel,
    .panelCaption.LastNamePanel,
    .panelCaption.ScreenNamePanel,
    .panelCaption.EmailPanel {
        width: 26.2rem;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .panelCaption.avatarsPanel{
       width: 30rem;
    }
}

@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .panelCaption.avatarsPanel{
        width: 24rem;
     }
}

@media (max-width: var(--ark-med-small-mobile-480)) {
    .panelCaption.avatarsPanel{
        width: 20rem;
     }
}
